import { Row, Table, flexRender } from '@tanstack/react-table';
import { Virtualizer } from '@tanstack/react-virtual';
import { CSSProperties } from 'react';

import { JUSTIFY_LEFT, JUSTIFY_RIGHT, MIN_CELL_WIDTH } from './constants';
import { ROW_HEIGHT } from './private/constants';

import stylesIndexPage from '../SpotonIndexTable/SpotonIndexTable.module.scss';
import stylesCustomView from './SpotonDataTable.module.scss';

import { PrivateColumnDefs } from './types';
import { checkIsProdOrStaging, getConfigVar } from 'features/common/utils';

export function DesktopTableRenderer({
  table,
  rows,
  tableClassName,
  handleDragStart,
  handleDragOver,
  handleOnDrop,
  dragOverId,
  dragDirection,
  dragStart,
  rowVirtualizer,
  rowHeight,
  groupingColumns,
  isSpecificCustomViewPage,
  pagination,
  chosenColumns,
}: {
  table: Table<any>;
  tableClassName: string;
  handleDragStart: (e: React.DragEvent<HTMLDivElement>) => void;
  handleDragOver: (e: React.DragEvent<HTMLDivElement>) => void;
  handleOnDrop: (e: React.DragEvent<HTMLDivElement>) => void;
  dragOverId: string;
  dragDirection: string;
  dragStart: string;
  rows: Row<any>[];
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
  rowHeight?: number;
  groupingColumns: string[];
  isSpecificCustomViewPage: boolean;
  pagination: { pageIndex: number; pageSize: number };
  chosenColumns: string[];
}) {
  const styles = isSpecificCustomViewPage ? stylesCustomView : stylesIndexPage;

  if (!isSpecificCustomViewPage) {
    rowHeight = 50;
  }

  // May use this variable later
  console.log('chosenColumns are:', chosenColumns);

  // to get rid of all the warnings about it not being set.
  // come back once we wrap up the feature
  const shouldShowGroupingInfo = checkIsProdOrStaging()
    ? false
    : getConfigVar('REACT_APP_SHOW_GROUPING_INFO') === 'true';
  // console.log('Should show grouping info:', shouldShowGroupingInfo); // Log the value to console
  return (
    <table className={`${tableClassName} ${styles.SpotonTable} ${styles['SpotonTable___borderBox']}`}>
      <thead className={styles.Head}>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr className={styles.HeaderRow} key={headerGroup.id} style={{ display: 'flex', width: '100%' }}>
            {isSpecificCustomViewPage && (
              <th className={`${styles.HeaderCell} ${styles.RowNumberHeader}`}>
                <div className={`${styles.RowNumberInner} ${styles.HeaderContainer}`}>#</div>
              </th>
            )}
            {headerGroup.headers.map((header: any) => {
              const colDef = header?.column?.columnDef ?? {};
              return (
                <th
                  id={colDef.accessorKey}
                  className={`${styles.HeaderCell} ${
                    dragOverId === colDef.accessorKey && colDef.accessorKey !== dragStart
                      ? dragDirection === 'left'
                        ? styles.DraggedCellLeft
                        : styles.DraggedCellRight
                      : `${styles.HeaderCell}`
                  }  `}
                  key={header.id}
                  style={{
                    width: header.getSize(),
                    minWidth: header.column.columnDef.so_fixedWidth ?? MIN_CELL_WIDTH,
                    maxWidth: header.column.columnDef.so_fixedWidth ?? undefined,
                    borderRight: '10px solid $base20',
                    backgroundColor: (() => {
                      return shouldShowGroupingInfo && groupingColumns.includes(colDef.accessorKey)
                        ? 'lightblue'
                        : 'transparent';
                    })(),
                  }}
                >
                  <div
                    style={{
                      width: header.getSize(),
                      minWidth: header.column.columnDef.so_fixedWidth ?? MIN_CELL_WIDTH,

                      maxWidth: header.column.columnDef.so_fixedWidth ?? undefined,
                    }}
                    {...{
                      className: header.column.getCanSort()
                        ? `${styles.HeaderContainer} cursor-pointer select-none`
                        : styles.HeaderContainer,
                    }}
                  >
                    <div
                      className={styles.HeaderDragContainer}
                      id={colDef.accessorKey}
                      draggable
                      onDragStart={handleDragStart}
                      onDragOver={handleDragOver}
                      onDrop={handleOnDrop}
                    >
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </div>

                    {header.column.getCanResize() && (
                      <div
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className={`${styles.Resizer} ${header.column.getIsResizing() ? styles.IsResizing : ''} ${
                          dragOverId === colDef.accessorKey && colDef.accessorKey !== dragStart
                            ? dragDirection === 'left'
                              ? ''
                              : styles.DraggedCellRight
                            : ``
                        }`}
                      ></div>
                    )}
                  </div>
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody
        className={styles.TableBody}
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
        }}
      >
        {rows.map((row: Row<any>, index: number) => {
          return (
            <tr
              className={styles.TableRow}
              key={row.id}
              style={{
                transform: `translateY(${(row as any).start}px)`,
                height: rowHeight ?? ROW_HEIGHT,
              }}
            >
              {isSpecificCustomViewPage && (
                <td className={`${styles.Cell} ${styles.RowNumberCell}`}>
                  <div className={styles.RowNumberInner}>{index + 1 + pagination.pageIndex * pagination.pageSize}</div>
                </td>
              )}
              {row.getVisibleCells().map((cell) => {
                const colDef = cell.column.columnDef as PrivateColumnDefs<any>;

                const alignRight = colDef.so_justifyContent === JUSTIFY_RIGHT;
                const alignLeft = colDef.so_justifyContent === JUSTIFY_LEFT;

                const alignmentClass = alignRight
                  ? styles.CellContentContainerAlignRight
                  : alignLeft
                  ? styles.CellContentContainerAlignLeft
                  : styles.CellContentContainerAlignCenter;

                return (
                  <td
                    className={`${styles.Cell} ${alignmentClass} ${
                      dragOverId === colDef.accessorKey && colDef.accessorKey !== dragStart
                        ? dragDirection === 'left'
                          ? styles.DraggedCellLeft
                          : styles.DraggedCellRight
                        : ''
                    }`}
                    key={cell.id}
                    style={
                      {
                        minWidth: colDef.so_fixedWidth ?? MIN_CELL_WIDTH,
                        maxWidth: colDef.so_fixedWidth ?? undefined,
                        width: cell.column.getSize(),
                        // height: rowHeight ?? ROW_HEIGHT,
                      } as CSSProperties
                    }
                  >
                    <div className={styles.CellContentContainer}>
                      <div className={styles.CellContentContainerInner}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </div>
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
