import {
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  SortingState,
  GroupingState,
  Row,
  RowSelectionState,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  ColumnFilter,
  ColumnSort,
  ColumnFiltersState,
  OnChangeFn,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import moment, { Moment } from 'moment';
import Papa from 'papaparse';
import React, { useState, useEffect, useRef } from 'react';
import { Dropdown, IconButton, colors, Button, LoadingIndicator } from 'spoton-lib';
import { SelectedRange } from 'spoton-lib/dist/cjs/types/src/components/DateRangeFilterSidebar';

import { ColumnChooser } from './ColumnChooser';
import { DateRangePicker } from './DateRangePicker';
import { FilterBar } from './FilterBar';
import { getGroupedRowModel } from './GroupRowModel';
import { useFlexColumns } from './hooks/useFlexColumns';
import { DRAG_LEFT, DRAG_RIGHT, DRAG_UNSET, PARENT_CONTAINER_ID, getRowHeight } from './private/constants';
import styles from './SpotonDataTable.module.scss';
import { TableRenderer } from './SpotOnDataTableRenderer';
import { SpotOnTanstackColDefs, StringToBooleanDict, StringToDataSourceDict } from './types';
import { checkIsProdOrStaging, getConfigVar } from '../../utils/config.utils';
import { logVisibleTableVals } from '../../utils/logVisibleTableVals';
import { useSize } from 'features/common/hooks/useSize';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'app/components/App/App.store';
import { openDataSourceConfiguration } from 'app/components/App/App.slice';
import GroupingInfo from './GroupingInfo.component';
import { getGroupingColumns } from 'features/common/utils/groupingUtils';

type DragDirection = typeof DRAG_LEFT | typeof DRAG_RIGHT | typeof DRAG_UNSET;

export type SpotonTableProps = {
  columnDefs: SpotOnTanstackColDefs<any>[];

  data: any[];

  // use mobile table default to false
  isMobile?: boolean;

  // use this to set the row height instead of the default.
  rowHeight?: number;

  // if true the table will show a loading indicator
  dataTableLoading?: boolean;

  tableClassName?: string;

  isSpecificCustomViewPage?: boolean;
  // if true user will not be able to choose or reorder columns
  disableMenu?: boolean;

  columnOrder: string[];
  onColumnOrderChanged?: (columnOrder: string[]) => void;

  columnSort?: SortingState;
  onColumnSortChanged?: (columnSort: ColumnSort[]) => void;

  chosenColumns: string[];
  onChosenColumnsChanged?: (chosenColumns: string[]) => void;

  // when grouping is enabled we will infer grouping columns from the chosen columns and metadata.
  // every column in chosenColumns that is not an aggregation function will be treated as a grouping column.
  enableGrouping?: boolean;

  // this gets used as the name of the csv file when exporting the table
  viewName?: string;

  filters?: ColumnFilter[];

  onFiltersChanged?: OnChangeFn<ColumnFiltersState>;

  //callback when save is pressed
  onSaveClicked?: () => void;

  ////////////////////////////////////////////////////////////////////////////////
  // row selection props start here
  // If rowSelectionEnabled the table renders edit and remove buttons for selected rows.
  // It is mostly dumb and simply passes the onClick events up to the parent to handle.
  rowSelectionEnabled?: boolean;
  rowSelectionCount?: number;
  // These are the callbacks for the edit and remove buttons.
  onEditClick?: () => void;
  onRemoveClick?: () => void;
  // row selection props end here
  ////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////
  // these props are used in conjunction with the data catalog
  dataCatalog?: StringToDataSourceDict | null;
  startDate?: Date | null | undefined;
  endDate?: Date | null | undefined;
  selectedRange?: string;
  dataSource?: string;

  // the currently selected location
  location?: string;
  // the list of available locations
  availableLocations?: any[];
  // callback when user selects a new location
  onLocationChanged?: (location: any) => void;
  onDatesChanged?: ({
    startDate,
    endDate,
    selectedRange,
  }: {
    startDate: string;
    endDate: string;
    selectedRange: string;
  }) => void;
  onSelectedRangeChanged?: (selectedRange: string) => void;
  onDataSouceChanged?: (dataSource: string) => void;

  // dataCatalog Props end here
  ////////////////////////////////////////////////////////////////////////////////
  locations?: any[];

  availableTemplates?: { value: string; label: string }[];
  chosenTemplate?: string;
  onTemplateChanged?: (template: number) => void;
  pageSize?: number;
  useDescriptiveGrouping?: boolean;
};

function applyPostAggFilters(row: any, filters: any) {
  return filters.every((filter) => {
    const { value, id } = filter;
    const { filterType, params } = value;

    if (filterType === 'inclusiveNumericRange') {
      const rowValue = row.getValue(id);

      if (params.min !== '' && params.max !== '') {
        return rowValue >= params.min && rowValue <= params.max;
      } else if (params.min === '' && params.max === '') {
        return true;
      } else if (params.min === '') {
        return rowValue <= params.max;
      } else if (params.max === '') {
        return rowValue >= params.min;
      } else {
        return true;
      }
    }
    return false;
  });
}

function getPostAggFilters(filters) {
  return filters.filter((filter) => {
    return filter.value.params.filterTiming === 'postAgg';
  });
}

export function SpotonTable({
  data,
  columnDefs,
  rowHeight,
  columnOrder,
  chosenColumns,
  tableClassName = styles.SpotonTable,
  disableMenu = false,
  dataTableLoading = false,
  rowSelectionEnabled = false,
  rowSelectionCount = 0,
  filters = [],
  isSpecificCustomViewPage,
  onFiltersChanged,
  onEditClick,
  onRemoveClick,
  onSaveClicked,
  onColumnOrderChanged,
  columnSort,
  onColumnSortChanged,
  enableGrouping: initialEnableGrouping = false,
  viewName = 'unnamed',
  // data catalog props start here
  dataSource,
  dataCatalog = null,
  startDate,
  endDate,
  selectedRange,
  onDatesChanged,
  location,
  availableLocations,
  onLocationChanged,
  availableTemplates,
  isMobile = false,
  pageSize = 30,
  useDescriptiveGrouping = true,
}: SpotonTableProps) {
  const postAggFilters = getPostAggFilters(filters);

  const [enableGrouping, setEnableGrouping] = useState(initialEnableGrouping);
  const [colsCausingNoGrouping, setColsCausingNoGrouping] = useState<string[]>();
  const [sorting, setSorting] = useState<SortingState>(columnSort ?? []);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [dragOverId, setDragOverId] = useState('');
  const [dragStart, setDragStart] = useState('');
  const [dragDirection, setDragDirection] = useState<DragDirection>('');
  const [dragStartPosition, setDragStartPosition] = useState(0);
  const [autoScrollDirection, setAutoScrollDirection] = useState<DragDirection>(DRAG_UNSET);
  const scrollTimerRef = useRef<NodeJS.Timeout | null>();

  const [grouping, setGrouping] = useState<GroupingState>(getGroupingColumns(chosenColumns, columnDefs));
  const [columnVisibility, setColumnVisibility] = useState<StringToBooleanDict>({});

  const editModalOpened = useSelector((state: any) => state.app.createNewCustomViewModalOpened);

  const dispatch = useDispatch<AppDispatch>();

  const shouldShowGroupingInfo = !checkIsProdOrStaging() && getConfigVar('REACT_APP_SHOW_GROUPING_INFO') === 'true';

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: pageSize,
  });

  useDescriptiveGrouping;
  // console.log('useDescriptiveGrouping', useDescriptiveGrouping);

  useEffect(() => {
    // this might look a little odd. but the reason we do this because we maintain
    // the state in the case where our parent doesnt track this state. because of this
    // we need to sync the state when the parent changes the prop.
    setSorting(columnSort ?? []);
  }, [columnSort]);

  useEffect(() => {
    const chosenColumnSet = new Set(chosenColumns);

    const getColumnVisibilityState = () => {
      // iterate column defs. if this column is not in the chosen columns
      // set it to be invisible.

      return columnDefs.reduce((acc, current) => {
        const column = current.id ?? (current.accessorKey as string);
        const showColumn = chosenColumnSet.has(column);
        return { ...acc, [column]: showColumn };
      }, {});
    };

    // I want to check for existence of at least one column in chosenColumnSet (that has been selected) where
    // so_uniq_val_no_grouping_applied is true. If it exists, I want to set enableGrouping to false
    // and set the grouping to ['globalId']

    const uniqueValueNoGroupingColumns: string[] = columnDefs
      .filter((i) => chosenColumnSet.has(i.accessorKey as string) && i.so_uniq_val_no_grouping_applied)
      .map((i) => i.accessorKey as string);

    const hasUniqueValueNoGroupingApplied = uniqueValueNoGroupingColumns.length > 0;

    if (hasUniqueValueNoGroupingApplied) {
      setEnableGrouping(false);
      setColsCausingNoGrouping(uniqueValueNoGroupingColumns);
    } else {
      setEnableGrouping(true);
    }

    if (enableGrouping) {
      setGrouping(getGroupingColumns(chosenColumns, columnDefs));
    }
    setColumnVisibility(getColumnVisibilityState());
  }, [columnDefs, chosenColumns, enableGrouping]);

  useEffect(() => {
    // Before doing anything, cancel any pending timers. This timer will run until autoScrollDirection
    // changes state. This is set in dragover handler, drop and dragleave events.
    if (scrollTimerRef.current !== null) {
      clearInterval(scrollTimerRef.current);
      scrollTimerRef.current = null;
    }

    const scroll = (step: number) => {
      const w = tableContainerRef.current as HTMLDivElement;
      // This timer allows the user to rest the mouse in the same postion
      // while dragging to advance the scroll position
      scrollTimerRef.current = setInterval(() => {
        const scrollY = w.scrollTop;
        const scrollX = w.scrollLeft;
        w.scrollTo(scrollX + step, scrollY);
      }, 10);
    };

    if (autoScrollDirection !== DRAG_UNSET) {
      if (autoScrollDirection === DRAG_LEFT) {
        scroll(-2);
      } else if (autoScrollDirection === DRAG_RIGHT) {
        scroll(2);
      } else {
        console.warn(
          `unsupported scroll direction ${autoScrollDirection}. Must be one of ${DRAG_LEFT}, ${DRAG_RIGHT}, or ${DRAG_UNSET}`,
        );
      }
    }
  }, [autoScrollDirection]);

  useSize({
    containerElement: document.getElementById(PARENT_CONTAINER_ID),
    onWidthChanged: setWidth,
  });

  useEffect(() => {
    if (onColumnSortChanged != null) {
      onColumnSortChanged(sorting);
    }
  }, [sorting, onColumnSortChanged]);

  const table = useReactTable({
    data,
    columns: columnDefs.map((col) => {
      if (col.accessorKey === 'closeTime' || col.accessorKey === 'openTime') {
        return {
          ...col,
          cell: ({ getValue }) => {
            const value = getValue();
            if (!value) return null;
            return moment(value).format('hh:mm A');
          },
        };
      }
      return col;
    }),
    getGroupedRowModel: getGroupedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onRowSelectionChange: setRowSelection,
    onGroupingChange: setGrouping,

    onColumnFiltersChange: onFiltersChanged,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    //debugTable: true,
    // debugHeaders: true,
    // debugColumns: true,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onColumnVisibilityChange: setColumnVisibility,
    // we need this to allow the metric columns to be reordered
    groupedColumnMode: false,

    filterFns: {
      dateRange: (row, columnId, filterValue) => {
        // this is filtered after aggregation
        if (filterValue.params.filterTiming === 'postAgg') {
          return true;
        }

        const rowValue = row.original[columnId];
        const { startDate, endDate } = filterValue.params;

        // the [] means inclusive
        return moment(rowValue).isBetween(startDate, endDate, null, '[]');
      },
      set: (row, columnId, filterValue) => {
        // this is filtered after aggregation
        if (filterValue.params.filterTiming === 'postAgg') {
          return true;
        }
        const rowValue = row.original[columnId];

        // we store column to false map so that means
        // we return true as long as the column is not in the map.
        // check the filter state to see how we set the filter value.
        if (filterValue != null) {
          return filterValue.params[rowValue] != false;
        }

        return true; // true or false based on your custom logic
      },

      text: (row, columnId, filterValue) => {
        // this is filtered after aggregation
        if (filterValue.params.filterTiming === 'postAgg') {
          return true;
        }
        const rowValue = row.original[columnId];
        const filterText = filterValue.params.search.toLowerCase();

        // this is filtered after aggregation
        if (filterValue.params.filterTiming === 'postAgg') {
          return true;
        }

        return rowValue.toLowerCase().includes(filterText);
      },

      inclusiveNumericRange: (row, columnId, filterValue) => {
        // this is filtered after aggregation
        if (filterValue.params.filterTiming === 'postAgg') {
          return true;
        }

        const rowValue = row.original[columnId];
        const { min, max } = filterValue.params;

        if (min !== '' && max !== '') {
          return rowValue >= min && rowValue <= max;
        } else if (min === '' && max === '') {
          return true;
        } else if (min === '') {
          return rowValue <= max;
        } else if (max === '') {
          return rowValue >= min;
        } else {
          return true;
        }
      },
    },

    state: {
      pagination,
      grouping,
      sorting,
      rowSelection,
      columnOrder,
      columnVisibility,
      columnFilters: filters,
    },
    onPaginationChange: setPagination,
  });

  useEffect(() => {
    if (getConfigVar('REACT_APP_PRINT_DATA_TABLE_VALUES') === 'true') {
      logVisibleTableVals(table);
    }
  }, [table, filters, columnSort, data]);

  useFlexColumns({
    table,
    columnVisibility,
    columnDefs,
    width,
    grouping,
    chosenColumns,
    isMobile,
  });

  const rowsBeforeFilter = table.getRowModel().rows;

  const filteredRows = rowsBeforeFilter.filter((row) => applyPostAggFilters(row, postAggFilters));

  const rowVirtualizer = useVirtualizer({
    count: filteredRows.length, //table.getRowModel().rows.length,
    estimateSize: () => getRowHeight(isMobile, enableGrouping ? grouping?.length - 1 : undefined, rowHeight), //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  const firstLoad = useRef(false);

  useEffect(() => {
    // HACK: when loading this component with filters already created, tanstack does not apply the filters.
    // this is a workaround to apply the filters after the table has loaded.
    if (dataTableLoading === true && firstLoad.current === false) {
      firstLoad.current = true;
    } else {
      if (dataTableLoading === false && firstLoad.current) {
        firstLoad.current = false;

        const persisted = table.getState().columnFilters;
        table.setColumnFilters(persisted);
      }
    }
  }, [table, dataTableLoading]);

  const rows: Row<any>[] = rowVirtualizer.getVirtualItems().map((virtualRow) => {
    const row = filteredRows[virtualRow.index] as Row<any>;
    return { ...row, start: virtualRow.start };
  });

  const reorderColumn = (movingColumnId: string, targetColumnId: string) => {
    const newColumnOrder = [...columnOrder];
    newColumnOrder.splice(
      newColumnOrder.indexOf(targetColumnId),
      0,
      newColumnOrder.splice(newColumnOrder.indexOf(movingColumnId), 1)[0],
    );
    if (onColumnOrderChanged != null) {
      onColumnOrderChanged(newColumnOrder);
    }
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    const { id } = e.target as HTMLDivElement;
    e.dataTransfer.setData('colIdx', id);
    setDragStart(id);
    // set the position so we can know what direction we are dragging
    setDragStartPosition(e.clientX);
  };

  const handleOnDrop = (e: React.DragEvent<HTMLDivElement>) => {
    const draggedColIdx = e.dataTransfer.getData('colIdx');
    reorderColumn(draggedColIdx, e.currentTarget.id);
    setDragOverId('');
    setDragStart('');
    setAutoScrollDirection(DRAG_UNSET);
  };

  // const availableDataSources = dataCatalog
  //   ? Object.keys(dataCatalog).map((i) => ({
  //       label: i,
  //       value: i,
  //     }))
  //   : [];

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    if (e.clientX < dragStartPosition) {
      setDragDirection(DRAG_LEFT);
    } else {
      setDragDirection(DRAG_RIGHT);
    }

    if (e.clientX > width - 100) {
      setAutoScrollDirection(DRAG_RIGHT);
    } else if (e.clientX < 200) {
      setAutoScrollDirection(DRAG_LEFT);
    }

    if (dragOverId !== e.currentTarget.id) {
      setDragOverId(e.currentTarget.id);
    }

    e.preventDefault();
  };

  function handleExportRows(rowData: Row<any>[]) {
    const rd = rowData.map((row) => row.original);

    // this will get rid of columns that are not in the chosen columns
    const cleansedRowData = rd.map((row) => {
      return chosenColumns.reduce((acc, current) => {
        return {
          ...acc,
          [current]: row[current],
        };
      }, {});
    });

    const csv = Papa.unparse(cleansedRowData);

    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    let csvURL = null;
    if ((navigator as any).msSaveBlob) {
      csvURL = (navigator as any).msSaveBlob(csvData, 'download.csv');
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', `${viewName}.csv`);
    tempLink.click();
  }

  return (
    <>
      {isMobile && <div> </div>}
      {!isMobile && (
        <div className={styles.SpotonTableAndControls}>
          {/* 1st row here is conditional and contains all components asociated with fetching rows*/}
          {dataCatalog != null &&
            startDate != null &&
            endDate != null &&
            availableTemplates != null &&
            availableLocations != null && (
              <div className={styles.DataQueryRow}>
                <div className={styles.LocationPicker}>
                  <div className={styles.DataQueryPickerDropdown}>
                    <Dropdown
                      isDisabled={true}
                      label="Location"
                      data-testid="LocationSourcePicker"
                      withDividers={true}
                      options={availableLocations}
                      value={availableLocations?.find((i) => {
                        return i.value === location;
                      })}
                      onChange={(e) => {
                        const item = e as { value: string };
                        if (onLocationChanged != null) {
                          onLocationChanged(item.value);
                        }
                      }}
                    />
                  </div>
                </div>

                <div className={styles.DateRangeAndSaveButtonContainer}>
                  <div className={styles.DateRangePickerContainer}>
                    <label className={styles.DateRangeLabel}>
                      {`${
                        (dataCatalog != null && dataCatalog![dataSource].primaryDateColumnName) ?? 'Business Date'
                      } Range`}
                    </label>
                    <DateRangePicker
                      //timeZone="UTC"
                      startDate={moment(startDate)}
                      endDate={moment(endDate)}
                      selectedRange={selectedRange as SelectedRange}
                      onDatesChange={(e: {
                        selectedRange: string;
                        startDate: Moment;
                        endDate: Moment;
                        customSelectedRange: string;
                      }) => {
                        const { startDate, endDate, selectedRange } = e;

                        if (onDatesChanged != null) {
                          onDatesChanged({
                            startDate: startDate.format('YYYY-MM-DD'),
                            endDate: endDate.format('YYYY-MM-DD'),
                            selectedRange,
                          });
                        }
                      }}
                    />
                  </div>
                  <div className={styles.SaveCustomViewButtonContainer}>
                    <Button
                      className={styles.SaveCustomViewButton}
                      onClick={() => {
                        if (onSaveClicked != null) {
                          onSaveClicked();
                        }
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            )}
          {/* next row here contains the filters and the column chooser button. it conditionally 
        renders edit and delete buttons when rowSelection is enabled  */}
          {isSpecificCustomViewPage && shouldShowGroupingInfo && (
            <GroupingInfo
              enableGrouping={enableGrouping}
              chosenColumns={chosenColumns}
              columnDefs={columnDefs}
              colsCausingNoGrouping={colsCausingNoGrouping}
            />
          )}

          <div className={styles.FilterRow}>
            <div className={styles.FilterRowLeft}>
              {rowSelectionEnabled && rowSelectionCount > 0 && (
                <div className={styles.EditRemoveSelectedItemsButtonContainer}>
                  <Button
                    data-testid="EditItemsButton"
                    variant="secondary"
                    onClick={() => {
                      if (onEditClick != null) {
                        onEditClick();
                      }
                    }}
                  >
                    Edit Items
                  </Button>
                  <Button
                    data-testid="RemoveItemsButton"
                    variant="secondary"
                    onClick={() => {
                      if (onRemoveClick != null) {
                        onRemoveClick();
                      }
                    }}
                  >
                    Remove Items
                  </Button>
                </div>
              )}

              {!dataTableLoading && <FilterBar filters={filters} table={table} columnDefs={columnDefs} />}
            </div>
            {isSpecificCustomViewPage && (
              <div className={styles.FilterRowRight}>
                <IconButton
                  disableBorder
                  size={24}
                  color={colors.black}
                  onClick={() => {
                    handleExportRows(table.getRowModel().rows);
                  }}
                  name="DownloadIcon"
                  alt={'CLOSE'}
                />
                {!disableMenu && (
                  <IconButton
                    variant="secondary"
                    name="Columns3Icon"
                    alt={'OPEN TABLE MENU BUTTON'}
                    data-testid="OpenTableMenuButton"
                    onClick={() => {
                      dispatch(openDataSourceConfiguration('edit'));
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {/* this last row contains the table itself   */}

      <div className={styles.TableContainerRef}>
        {dataTableLoading && (
          <>
            <div className={styles.LoadingText}>Loading, please wait...</div>
            <div className={styles.Loading}>
              <LoadingIndicator size="sm" greyAnimation />
            </div>
          </>
        )}
        <div ref={tableContainerRef} className={styles.TableContainerRef}>
          <TableRenderer
            key={isMobile ? 'mobile' : 'desktop'}
            rowHeight={rowHeight}
            table={table}
            rows={dataTableLoading ? [] : rows}
            rowVirtualizer={rowVirtualizer}
            tableClassName={tableClassName}
            handleDragStart={handleDragStart}
            handleDragOver={handleDragOver}
            handleOnDrop={handleOnDrop}
            groupingColumns={enableGrouping ? grouping : []}
            chosenColumns={chosenColumns}
            dragOverId={dragOverId}
            dragStart={dragStart}
            dragDirection={dragDirection}
            isMobile={isMobile}
            isSpecificCustomViewPage={isSpecificCustomViewPage}
            pagination={pagination}
          />
        </div>
      </div>
      {isSpecificCustomViewPage && (
        <div className={styles.paginationContainer}>
          <span className={styles.recordCount}>
            <span className={styles.currentRange}>
              {pagination.pageIndex * pagination.pageSize + 1} -{' '}
              {Math.min((pagination.pageIndex + 1) * pagination.pageSize, data.length)}
            </span>
            <span className={styles.totalRecords}> of {data.length}</span>
          </span>

          {/* Left Arrow */}
          <button
            onClick={() => setPagination((old) => ({ ...old, pageIndex: Math.max(old.pageIndex - 1, 0) }))}
            disabled={pagination.pageIndex === 0}
            className={styles.paginationButton}
          >
            {'<'}
          </button>

          {/* Page Number Input */}
          <input
            type="number"
            min={1}
            max={Math.ceil(data.length / pagination.pageSize)}
            value={pagination.pageIndex + 1} // Display current page (1-based)
            onChange={(e) => {
              const page = Math.max(1, Math.min(Math.ceil(data.length / pagination.pageSize), Number(e.target.value)));
              setPagination((old) => ({ ...old, pageIndex: page - 1 })); // Update pageIndex (0-based)
            }}
            className={styles.pageInput}
          />
          <span>of {Math.ceil(data.length / pagination.pageSize)}</span>

          {/* Right Arrow */}
          <button
            onClick={() =>
              setPagination((old) => ({
                ...old,
                pageIndex: Math.min(old.pageIndex + 1, Math.ceil(data.length / pagination.pageSize) - 1),
              }))
            }
            disabled={pagination.pageIndex >= Math.ceil(data.length / pagination.pageSize) - 1}
            className={styles.paginationButton}
          >
            {'>'}
          </button>
        </div>
      )}

      {editModalOpened && <ColumnChooser open={editModalOpened} />}
    </>
  );
}
