import { useEffect, useMemo, useRef, useState } from 'react';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { LoadingIndicator, showToast } from 'spoton-lib';

import styles from './CustomView.module.scss';
import { useDataCatalog, generateColumnDefs, SpotonTable } from '../../../common/components/SpotonDataTable';
import { isMobile } from 'features/common/utils/responsive.utils';
import { useSize } from 'features/common/hooks/useSize';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/components/App/App.store';

import {
  setColumnOrder,
  setColumnSort,
  setCurrentCustomView,
  setDateRange,
  setFilters,
  setLocation,
  setName,
} from 'app/components/App/App.slice';
import { saveCustomView } from 'app/components/App/App.hunks';
import { getConfigVar } from 'features/common';
import { isTemplateId } from 'features/custom_views/utils/customViews';
import { TOAST_CONTAINER_ID } from 'features/common/constants/toast.constants';
import moment from 'moment';

const FORCE_PAGE_RELOAD = true;
const DATA_START_DATE = '2024-09-15';

export function CustomView() {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const useLocalStorage = searchParams.get('useLocalStorage');

  const dataSourcesLoading = useSelector((state: RootState) => state.app.dataSourcesLoading);
  const customViewsLoading = useSelector((state: RootState) => state.app.customViewsLoading);
  const locationsLoading = useSelector((state: RootState) => state.app.locationsLoading);
  const saveCustomViewLoading = useSelector((state: RootState) => state.app.saveCustomViewLoading);

  const customViewsError = useSelector((state: RootState) => state.app.customViewsError);
  const dataSourcesError = useSelector((state: RootState) => state.app.dataSourcesError);
  const locationsError = useSelector((state: RootState) => state.app.locationsError);
  const saveCustomViewError = useSelector((state: RootState) => state.app.saveCustomViewError);

  const customViewsLoaded = useSelector((state: RootState) => state.app.customViewsLoaded);

  const error = customViewsError || dataSourcesError || locationsError || saveCustomViewError;

  const appLoading =
    (!error && dataSourcesLoading) ||
    customViewsLoading ||
    locationsLoading ||
    saveCustomViewLoading ||
    !customViewsLoaded;

  const locations = useSelector((state: RootState) => state.app.locationsData);
  const currentCustomView = useSelector((state: RootState) => state.app.currentCustomView);
  const dataCatalog = useSelector((state: RootState) => state.app.dataCatalog);

  const customViewNameRef = useRef(currentCustomView.name);

  const dispatch = useDispatch<AppDispatch>();
  const template = useSelector((state: RootState) => state.app.chosenTemplate);
  const templates = useSelector((state: RootState) => state.app.availableTemplates);

  const [width, setWidth] = useState(window.innerWidth);

  const storedCustomViewId = useSelector((state: RootState) => state.app.currentCustomView.id);

  const [filterLocalState, setFilterLocalState] = useState(currentCustomView.filters);
  useEffect(() => {
    // write to global state when local state changes
    dispatch(setFilters(filterLocalState));
  }, [dispatch, filterLocalState]);

  useEffect(() => {
    if (useLocalStorage == '1') {
      const current = localStorage.getItem('currentCustomView');

      if (current !== null) {
        const currentCustomView = JSON.parse(current);
        if (currentCustomView) {
          dispatch(setCurrentCustomView(currentCustomView.data));
        }
      }

      setSearchParams({});
    }
  }, [useLocalStorage, dispatch, setSearchParams]);

  useEffect(() => {
    if (appLoading) return;
    if (!useLocalStorage) {
      if (storedCustomViewId !== id && id !== 'new') {
        dispatch(setCurrentCustomView(id));
      }
    }
  }, [dispatch, id, storedCustomViewId, useLocalStorage, appLoading]);

  useEffect(() => {
    // we do this because we use useMemo to not re-render the table
    // when the customView name changes.
    customViewNameRef.current = currentCustomView.name;
  }, [currentCustomView]);

  useSize({
    containerElement: document.body,
    onWidthChanged: setWidth,
  });

  const onDateRangeChanged = ({ startDate, endDate, selectedRange }) => {
    if (!FORCE_PAGE_RELOAD) {
      dispatch(setDateRange({ startDate, endDate, selectedRange }));
    } else {
      localStorage.setItem(
        'currentCustomView',
        JSON.stringify(
          {
            timestamp: Date.now(),
            data: { ...currentCustomView, startDate, endDate, selectedRange },
          },
          null,
          4,
        ),
      );

      window.location.assign(`/custom-views/custom-view/${currentCustomView.id}?useLocalStorage=1`);
    }
  };

  const {
    rows: rowData,
    meta: metadata,
    tableLoading,
  } = useDataCatalog({
    startDate: currentCustomView.startDate,
    endDate: currentCustomView.endDate,
    dataCatalog,
    dataSource: currentCustomView.dataSource,
    location: currentCustomView.location,
  });

  const onSaveCustomView = () => {
    const isTemplate = isTemplateId(currentCustomView.id);

    const name = isTemplate ? ` Copy of ${customViewNameRef.current}` : customViewNameRef.current;

    // templates we are always saving a new copy. we cant edit these.
    const path =
      currentCustomView.id === 'new' || isTemplate
        ? `${getConfigVar('REACT_APP_BFF_BASE_URL')}/custom-views`
        : `${getConfigVar('REACT_APP_BFF_BASE_URL')}/custom-views/${currentCustomView.id}`;

    const method = currentCustomView.id === 'new' || isTemplate ? 'POST' : 'PATCH';

    dispatch(
      saveCustomView({
        path,
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...currentCustomView, name }),
        credentials: 'include',
      }),
    );
  };

  const handleCustomViewNameChange = (evt: ContentEditableEvent) => {
    dispatch(setName(evt.target.value));
  };

  const columnDefs =
    metadata != null
      ? generateColumnDefs({
          metadata,
          filters: currentCustomView?.filters ?? [],
        })
      : [];

  return (
    <div className={`${styles.CustomViewLayout}  ${isMobile() && styles.CustomViewLayout___mobile}`}>
      <div className={styles.CustomViewHeaderContainer}>
        <div className={`${styles.CustomViewHeader} ${isMobile() && styles.CustomViewHeader___mobile}`}>
          <div className={styles.CustomViewEditableTitle}>
            {!isMobile() && <ContentEditable html={currentCustomView.name} onChange={handleCustomViewNameChange} />}
          </div>
          {isMobile() && <div className={styles.CustomViewTitle}>{currentCustomView.name}</div>}

          {!isMobile() && (
            <div className={styles.SaveAndLinkBackToCustomViewsContainer}>
              <Link to={`/?reload=1`}>Custom Views</Link>
            </div>
          )}
        </div>
      </div>
      {/* memoizing so we dont render when the CustomView name changes */}
      {useMemo(() => {
        return !appLoading ? (
          <SpotonTable
            // this will force a complete teardown and re-render of the table when we tggle between mobile and desktop
            key={isMobile() ? 'mobile' : 'desktop'}
            isMobile={isMobile()}
            isSpecificCustomViewPage={true}
            viewName={currentCustomView.name}
            columnDefs={columnDefs}
            data={rowData}
            chosenColumns={currentCustomView.chosenColumns!}
            onSaveClicked={onSaveCustomView}
            enableGrouping={true}
            columnSort={currentCustomView.columnSort}
            onColumnSortChanged={(sort) => {
              // this check avoids an infinite loop. Oh! the joys of passing arrays by reference.
              if (JSON.stringify(sort) !== JSON.stringify(currentCustomView.columnSort)) {
                dispatch(setColumnSort(sort));
              }
            }}
            columnOrder={currentCustomView.columnOrder!}
            onColumnOrderChanged={(order) => {
              // not so sure ir this check is needed. i worry passing arrays are by reference
              if (JSON.stringify(order) !== JSON.stringify(currentCustomView.columnOrder)) {
                dispatch(setColumnOrder(order));
              }
            }}
            dataCatalog={dataCatalog}
            dataSource={currentCustomView.dataSource}
            startDate={currentCustomView.startDate}
            endDate={currentCustomView.endDate}
            selectedRange={currentCustomView.selectedRange}
            onDatesChanged={({ startDate, endDate, selectedRange }) => {
              const start = moment(startDate, 'YYYY-MM-DD');
              const dataStartDate = moment(DATA_START_DATE, 'YYYY-MM-DD');

              // Test if the date is before September 15
              if (start.isBefore(dataStartDate)) {
                showToast({
                  variant: 'danger',
                  content: `Data is only available from September 15th, 2024 onwards. Please choose a date after September 15th, 2024.`,
                  position: 'top-center',
                  containerId: TOAST_CONTAINER_ID,
                  buttonText: '',
                  limit: 2,
                });
              } else {
                onDateRangeChanged({ startDate, endDate, selectedRange });
              }
            }}
            dataTableLoading={tableLoading}
            filters={currentCustomView.filters}
            onFiltersChanged={(f) => {
              setFilterLocalState(f);
            }}
            availableLocations={locations}
            location={currentCustomView.location ?? locations[0]?.value}
            onLocationChanged={(val) => {
              dispatch(setLocation(val));
            }}
            availableTemplates={templates}
            chosenTemplate={template}
          />
        ) : (
          <div className={styles.LoaderContainer}>
            <div className={styles.LoadingText}>{`${
              saveCustomViewLoading ? 'Saving Custom View' : 'Loading Saved Custom Views'
            }`}</div>
            <div className={styles.Loading}>
              <LoadingIndicator size="sm" greyAnimation />
            </div>
          </div>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [
        currentCustomView.chosenColumns,
        rowData,
        metadata,
        currentCustomView.columnOrder,
        tableLoading,
        currentCustomView.filters,
        template,
        location,
        width,
        currentCustomView.chosenColumns,
        currentCustomView.columnSort,
        appLoading,
        currentCustomView.selectedRange,
      ])}
    </div>
  );
}

export default CustomView;
