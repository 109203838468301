import { createAsyncThunk } from '@reduxjs/toolkit';
import { showToast } from 'spoton-lib';

// Helper function to check cache expiration
const isCacheExpired = (timestamp: number, duration: number): boolean => {
  return Date.now() > timestamp + duration;
};

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
export async function sendAsync(
  {
    method = 'GET',
    path,
    body,
    headers,
    cacheKey, // must be set if you want to cache the data
    cacheDuration, // must be set if you want to cache the data
    credentials,
    retries = 0,
  }: {
    method?: string;
    path: string;
    body?: any;
    headers?: any;
    credentials?: RequestCredentials;
    cacheKey?: string;
    cacheDuration?: number;
    retries?: number;
  },
  { rejectWithValue = null },
) {
  // does it exist in the cache already and is it still valid?

  const cacheEnabled = cacheKey != null && cacheDuration != null;

  if (cacheEnabled) {
    // Check localStorage
    const cachedData = localStorage.getItem(cacheKey);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      if (!isCacheExpired(timestamp, cacheDuration)) {
        return data; // Return cached data if it's not expired
      }
    }
  }

  const array = Array.from({ length: retries + 1 }, (_, i) => i);
  const retryMode = retries > 0;

  for (const idx of array) {
    try {
      const response = await fetch(`${path}`, { method, headers, body, credentials });
      const data = await response.json();

      // Save to localStorage
      if (cacheEnabled) {
        localStorage.setItem(
          cacheKey,
          JSON.stringify({
            data,
            timestamp: Date.now(),
          }),
        );
      }

      return data as any; // Returns the user data which will be passed to `fulfilled`
    } catch (error) {
      console.log('Fetch Error:', error.message, idx);

      if (retryMode) {
        if (idx === retries) {
          showToast({
            variant: 'danger',
            content: `There was an unexpected error. Retried ${idx} times. ${error.message} `,
            position: 'top-center',
            buttonText: '',
            limit: 2,
          });
          if (rejectWithValue) {
            return rejectWithValue(`Failed to fetch. ${error.message}`);
          }
        } else {
          console.log('Retrying request after short delay, idx=', idx);
          await sleep(1000);
        }
      } else {
        showToast({
          variant: 'danger',
          content: `There was an unexpected error. ${error.message} `,
          position: 'top-center',
          buttonText: '',
          limit: 2,
        });
        if (rejectWithValue) {
          return rejectWithValue(`Failed to fetch. ${error.message}`);
        }
      }
    }
  }
}

export const customViews = createAsyncThunk('customViews/fetchAll', sendAsync);
export const dataSources = createAsyncThunk('dataSources/fetchAll', sendAsync);
export const locations = createAsyncThunk('locations/fetchAll', sendAsync);
export const saveCustomView = createAsyncThunk('customViews/saveCustomView', sendAsync);

export const bulkRemoveCustomViews = createAsyncThunk('customViews/bulkRemove', sendAsync);
export const bulkUpdateCustomViews = createAsyncThunk('customViews/bulkUpdate', sendAsync);
