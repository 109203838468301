import { IconButton, colors } from 'spoton-lib';
import styles from './HeaderRenderer.module.scss';
import { isMobile } from 'features/common/utils/responsive.utils';

export const headerAlignmentFunc = () => {
  const headerFuncInner = ({ header }: { header: any }) => {
    const headerText = header?.column?.columnDef?.so_oldHeader ?? '';
    const sortState = header.column.getIsSorted();

    const alignRight = header?.column?.columnDef?.so_justifyContent === 'right';
    const alignLeft = header?.column?.columnDef?.so_justifyContent === 'left';

    const alignmentClass = alignRight
      ? styles.HeaderTextContainerAlignRight
      : alignLeft
      ? styles.HeaderTextContainerAlignLeft
      : undefined;

    const sortIcon =
      sortState === 'asc' ? (
        <IconButton
          className={styles.IconButton}
          disableBorder
          size={18}
          color={colors.black}
          name="ArrowUpIcon"
          alt={'ASCENDING'}
          onClick={header.column.getToggleSortingHandler()}
        />
      ) : sortState === 'desc' ? (
        <IconButton
          className={styles.IconButton}
          disableBorder
          size={18}
          color={colors.black}
          name="ArrowDownIcon"
          alt={'DESCENDING'}
          onClick={header.column.getToggleSortingHandler()}
        />
      ) : (
        <IconButton
          className={styles.IconButton}
          disableBorder
          size={18}
          color={colors.gray30}
          name="SortIcon"
          alt={'UNSORTED'}
          onClick={header.column.getToggleSortingHandler()}
        />
      );

    return (
      <div
        className={styles.HeaderTextContainer}
        onClick={(e) => {
          header.column.getToggleSortingHandler()(e);
          e.stopPropagation();
        }}
      >
        <div className={alignmentClass}>
          {headerText}

          {/* Comment this out for screenshots for template selector*/}
          {!isMobile() && sortIcon}
        </div>
      </div>
    );
  };

  return headerFuncInner;
};
